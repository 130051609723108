
#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
  overflow: visible;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  overflow: visible;
}



.swiper-slide {
  background-position: center;
  //overflow: hidden;
  filter: blur(10px);
  width: 100px;
  

 /*  height: 596px; */
}


.sticky-navbar {
    position: sticky;
    top: 0px;
}

.swiper-slide-active{
    filter: blur(0px);
    
}
.swiper-slide img {
  display: block;
  width: 100%;
  
}

.swiper{
  overflow: hidden;
  
  
  /* width:500px; */
  
}
.swiper-slide{
  autoplay={{
    "delay": 2500,
    "disableOnInteraction": false
  }}
}

.close {

 
}
.close:hover {
  fill: #092398;
}

.h22{
  box-shadow: inset 0px 50.8326px 72.9904px -46.9224px rgba(255, 255, 255, 0.5), inset 0px -16.9442px 11.7306px -18.2476px rgba(255, 255, 255, 0.56), inset 0px 9.1238px 14.3374px -5.2136px rgba(255, 255, 255, 0.45), inset 0px 5.2136px 23.4612px rgba(74, 222, 128, 0.3), inset 0px 1.3034px 52.136px rgba(74, 222, 128, 0.05);
backdrop-filter: blur(50px);}
.h33{
  background: rgba(38, 38, 38, 0.5);
border: 1px solid #404040;
box-sizing: border-box;
border-radius: 8px;}

.b22{
  background: linear-gradient(92.14deg, #4ADE80 -30.32%, #6EE7B7 114.61%);
filter: blur(0px);
border-radius: 4px;
width: 267px;
height: 52px;
left: 826px;
top: 413px;
}

.faaq{
 
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
}

.bg23{
  filter:blur-[250px];
}

.meet1{
  

  blur-background: linear-gradient(92.14deg, #4ADE80 -30.32%, #6EE7B7 114.61%);
   

}

.ball{
  style=box-sizing:border-box;
  display:block;
  width:initial;
  height:initial;
  background:none;
  opacity:1;
  border:0;
  margin:0;
  padding:0;
  max-width:100%;
}
.left-btn { float: left; }
.right-btn { float: right; }

